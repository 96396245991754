// ** Initial State
const initialState = {
  publishedCatalogs: [],
  currentJob: null,
  params: {}
}

const JobReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PUBLISHED':
      return {
        ...state,
        publishedCatalogs: action.data
      }
    case 'JOB_JOB_DETAILS':
      return { ...state, currentJob: action.data }
    default:
      return state
  }
}

export default JobReducer