// ** Initial State
const initialState = {
  data: [],
  totalData: 0,
  selectedCatalog: null
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PRODUCTS':
      return { ...state, data: action.data, totalData: action.totalDocuments }
    case 'ADD_EVENT':
      return { ...state }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    default:
      return state
  }
}

export default productsReducer
